import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../templates/main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import FeatureSideImageSection from "../components/Organisms/FeatureSideImageSection"
import LogoCompaniesSection from "../components/Organisms/LogoCompaniesSection"
import ContactSection from "../components/Organisms/ContactSection"

const SobrePage = () => {
  const {
    mainBanner: {
      childImageSharp: { fluid: mainBanner },
    },
    contactFormImage: {
      childImageSharp: { fluid: contactFormImage },
    },
  } = useStaticQuery(
    graphql`
      query {
        mainBanner: file(relativePath: { eq: "banners/main-banner.jpg" }) {
          childImageSharp {
            fluid(base64Width: 1000, quality: 50) {
              base64
            }
          }
        }
        contactFormImage: file(relativePath: { eq: "banners/contact-banner.jpg" }) {
          childImageSharp {
            fluid(base64Width: 1000, quality: 50) {
              base64
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Sobre a Agência Paladino" />

      <Hero
        title="Sobre a Agência Paladino."
        description="Conheça nossa história e nosso propósito."
        fluidImage={mainBanner}
      />

      <FeatureSideImageSection
        title="Como a agência começou"
        description="A Agência Paladino nasceu em 2018 pelas mãos de dois amigos programadores. Eles tinham o desejo de proporcionar a pequenas e médias empresas acesso a serviços digitais de qualidade.
        \n\nComeçamos focando na criação de websites para diversos segmentos. Com a evolução, ampliamos nosso portfólio para incluir o desenvolvimento de lojas virtuais, sistemas web e aplicativos."
        image="/assets/ag1.jpg"
      />

      <FeatureSideImageSection
        title="Desafios que enfrentamos"
        description="Durante a pandemia de COVID-19, muitas empresas entenderam a importância de estabelecer uma presença online.
        \n\nNesse período, nos dedicamos ao desenvolvimento de soluções digitais para possibilitar que as empresas mantivessem suas operações em funcionamento.
        \n\nDesenvolvemos essas soluções em um curto espaço de tempo, sem abrir mão do alto padrão de qualidade que já oferecíamos. Felizmente, obtivemos sucesso em todas as nossas iniciativas."
        image="/assets/website-background.jpg"
        reverse={true}
      />

      <FeatureSideImageSection
        title="No que acreditamos"
        description="Acreditamos que soluções digitais potencializam o faturamento das empresas, reduzem custos e economizam tempo ao minimizar atividades repetitivas.
        \n\nPor isso, estamos sempre evoluindo para entregar soluções que aprimoram o posicionamento online, captam novos clientes, automatizam processos e otimizam a gestão dos negócios."
        image="/assets/instituicoes-1.png"
      />

      <LogoCompaniesSection />

      <ContactSection
        id="contato"
        fluidImage={contactFormImage}
        phoneNumberLink="tel:027996433420"
        whatsappNumberLink="https://api.whatsapp.com/send?phone=+5527996433420&text=Ol%C3%A1.%20Eu%20tenho%20interesse%20nos%20Servi%C3%A7os%20da%20Ag%C3%AAncia."
      />
    </Layout>
  )
}

export default SobrePage
